import React from 'react';
import PropTypes from 'prop-types';

const IconProfileSmall = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5 15.4861C6.3075 14.2153 8.07667 13.4336 10.0192 13.4336C11.9425 13.4336 13.6925 14.1994 15 15.4469C13.6925 16.7178 11.9233 17.4994 9.98083 17.4994C8.0575 17.4994 6.3075 16.7336 5 15.4861Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M3.3125 13.3542C2.80417 12.3425 2.5 11.21 2.5 10C2.5 5.855 5.855 2.5 10 2.5C14.145 2.5 17.5 5.855 17.5 10C17.5 11.2083 17.1975 12.3417 16.6858 13.3525"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M11.7678 6.56622C12.7441 7.54252 12.7441 9.12544 11.7678 10.1017C10.7915 11.0781 9.20854 11.0781 8.23223 10.1017C7.25592 9.12544 7.25592 7.54252 8.23223 6.56622C9.20854 5.58991 10.7915 5.58991 11.7678 6.56622"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconProfileSmall.propTypes = {
  className: PropTypes.string
};

IconProfileSmall.defaultProps = {
  className: ''
};

export default IconProfileSmall;
