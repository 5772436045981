import React from 'react';
import block from 'bem-cn';
import './ErrorFallback.scss';
import { FreshButton } from '@components/_fresh';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const b = block('ErrorFallback');

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => (
  <div className={b()} role="alert">
    <p className={b('title')}>
      It&apos;s look like something went wrong. <br /> It makes us sad, but we&apos;re already
      working on fixing it! 💖
    </p>

    <div className={b('buttonContainer')}>
      <FreshButton text="Try again" onClick={resetErrorBoundary} />
      <FreshButton
        color="grey-light"
        text="Contact us"
        onClick={() => window.open('mailto:info@storysdk.com', '_blank')}
      />
    </div>
  </div>
);
