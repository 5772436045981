import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import block from 'bem-cn';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { UserRole } from '@features/user/types';
import { DEFAULT_MAU_LIMIT } from '@features';
import {
  Logo,
  IconSelectArrowFresh,
  IconProfile,
  IconLogout,
  HeaderAction,
  Icon,
  HeaderSubscription,
  IconDashboardCard,
  IconDashboardStats
} from '@components';
import { Auth, logout } from '@utils';
import { Drop } from '@custom';
import { useGetUserSubscription } from '@hooks';
import { FreshButton } from '@components/_fresh';
import './Header.scss';

type HeaderProps = {
  leftNav?: React.ReactElement;
  centerNav?: React.ReactElement;
  className?: string;
  withLogo?: boolean;
  noSubscriptionStatus?: boolean;
};

const b = block('Header');

export const Header: React.FC<HeaderProps> = (props) => {
  const { leftNav, centerNav, className, withLogo = true, noSubscriptionStatus } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleLogout = () => {
    logout(dispatch);
    history.push('/');
  };

  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user.user);
  const appManager = useSelector((state: RootState) => state.appManager);
  const userSubcription = useGetUserSubscription();

  const isHasOwnedApp = useMemo(
    () =>
      appManager.appsList.length > 0 && appManager.appsList.find((app) => app.ownerId === user.id),
    [appManager.appsList, user.id]
  );

  const isShowUpgrade = useMemo(() => {
    if (
      userSubcription.mauLimit &&
      userSubcription.mauLimit !== DEFAULT_MAU_LIMIT &&
      userSubcription.mauUsage >= userSubcription.mauLimit - 1000
    ) {
      return true;
    }

    if (
      userSubcription.mauLimit === DEFAULT_MAU_LIMIT &&
      userSubcription.mauUsage >= DEFAULT_MAU_LIMIT - 100
    ) {
      return true;
    }

    return false;
  }, [userSubcription.mauLimit, userSubcription.mauUsage]);

  const getHeaderAction = () => {
    if (userSubcription.isUnpaid && !noSubscriptionStatus) {
      return (
        <HeaderAction
          actionLink={`${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${user.email}`}
          actionText={t('user.managePayments')}
          isExternal
          text={t('user.billingIssue')}
        />
      );
    }

    if (!noSubscriptionStatus && userSubcription.isLoaded && isHasOwnedApp) {
      return (
        <div className={b('subscriptionContainer')}>
          <HeaderSubscription isWarning={isShowUpgrade} />

          {isShowUpgrade && (
            <FreshButton
              leftIcon={<Icon name="upgrade-arrow" variant="icons" />}
              size="sm"
              text={t('plans.upgrade')}
              onClick={() => {
                history.push('/account/billing');
              }}
            />
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <header className={b()}>
      <div className={cn(`${b('container', { noLogo: !withLogo })}`, className)}>
        <div className={b('col')}>
          {withLogo && (
            <Link className={b('brand')} to="/">
              <Logo color="black" />
            </Link>
          )}
          <div className={b('nav')}>{leftNav}</div>
        </div>
        <div className={b('col', { centered: true })}>{centerNav}</div>
        <div className={b('col', { right: true })}>
          {getHeaderAction()}
          <div className={b('separator')} />
          <Drop
            control={
              <div className={b('authControl')}>
                <IconProfile className={b('authControlIcon').toString()} />

                <div className={b('authControlContainer')}>
                  <span className={b('authControlText')}>
                    {Auth.user ? Auth.user.fullName : 'unknown'}
                  </span>

                  {user.role === UserRole.ADMIN && (
                    <span className={b('authControlText', { small: true })}>
                      {t('nav.administrator')}
                    </span>
                  )}
                </div>

                <IconSelectArrowFresh className={b('authControlArrow').toString()} />
              </div>
            }
            isRight
          >
            <div className={cn('SelectDropdown', `${b('authControlDropdown')}`)}>
              <Link className={b('authControlDropdownItem')} to="/account/settings">
                <IconProfile className={b('authControlDropdownItemIcon').toString()} />
                {t('nav.account')}
              </Link>
              <Link className={b('authControlDropdownItem')} to="/account/billing">
                <IconDashboardCard
                  className={b('authControlDropdownItemIcon', {
                    fill: true
                  }).toString()}
                />
                {t('nav.billing')}
              </Link>
              {isHasOwnedApp && (
                <Link className={b('authControlDropdownItem')} to="/account/usage">
                  <IconDashboardStats
                    className={b('authControlDropdownItemIcon', {
                      fill: true
                    }).toString()}
                  />
                  {t('nav.usage')}
                </Link>
              )}
              <div
                className={b('authControlDropdownItem')}
                role="button"
                tabIndex={-1}
                onClick={handleLogout}
                onKeyDown={handleLogout}
              >
                <IconLogout className={b('authControlDropdownItemIcon').toString()} />
                {t('nav.logout')}
              </div>
            </div>
          </Drop>
        </div>
      </div>
    </header>
  );
};
