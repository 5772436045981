import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';
import './NavButton.scss';
import { IconBackFresh } from '@components';

interface Props {
  text: string;
  link?: string;
  onClick?(): void;
}

const b = block('NavButton');

export const NavButton: React.FunctionComponent<Props> = ({ text, link, onClick }) => (
  <Link className={b()} to={link} onClick={onClick}>
    <IconBackFresh className={b('icon')} />
    {text}
  </Link>
);
