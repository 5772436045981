import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import block from 'bem-cn';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Logo, IconDashboardCard, IconDashboardStats, IconProfileSmall } from '@components';
import './AccountSideNav.scss';

const b = block('AccountSideNav');

interface AccountSideNavProps {
  isLoading?: boolean;
  onlyLogo?: boolean;
  className?: string;
  isHasOwnedApp?: boolean;
}

export const AccountSideNav: React.FC<AccountSideNavProps> = (props) => {
  const { className, isLoading, onlyLogo, isHasOwnedApp } = props;
  const location = useLocation();
  const tab = location.pathname.split('/')[2];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const bodyHeight = document.body.clientHeight;

  const { t } = useTranslation();

  return (
    <nav
      className={cn(
        b({
          open: isMenuOpen
        }).toString(),
        className
      )}
      style={{
        height: bodyHeight
      }}
    >
      <Link className={b('brand')} to="/">
        <Logo className={b('logo')} color="black" />
      </Link>

      <button
        className={b('toogleBtn', { close: isMenuOpen })}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <span className={b('toogleBtnLine')} />
      </button>

      {!onlyLogo && (
        <div>
          {isLoading ? (
            <div className={b('loadContainer')}>
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
            </div>
          ) : (
            <div className={b('linkContainer')}>
              <Link
                className={b('link', {
                  current: tab === 'settings'
                })}
                to="/account/settings"
                onClick={() => setIsMenuOpen(false)}
              >
                <IconProfileSmall
                  className={b('icon', {
                    small: true
                  }).toString()}
                />
                {t('nav.account')}
              </Link>
              <Link
                className={b('link', {
                  current: tab === 'billing'
                })}
                to="/account/billing"
                onClick={() => setIsMenuOpen(false)}
              >
                <IconDashboardCard
                  className={b('icon', {
                    filled: true
                  }).toString()}
                />
                {t('nav.billing')}
              </Link>
              {isHasOwnedApp && (
                <Link
                  className={b('link', {
                    current: tab === 'usage'
                  })}
                  to="/account/usage"
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  <IconDashboardStats
                    className={b('icon', {
                      filled: true
                    }).toString()}
                  />
                  {t('nav.usage')}
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </nav>
  );
};
