import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import block from 'bem-cn';
import './Tabs.scss';

const b = block('Tabs');

export type TabType = {
  id: string;
  title: string;
  props?: any;
  component?: React.ComponentType<any>;
  link?: string;

  onClick?: () => void;
};

interface Props {
  initialTabId?: string;
  tabHeaderControl?: React.ReactChild;
  tabsLineControl?: React.ReactChild;
  tabs: TabType[];
  isTabLineHidden?: boolean;
}

export const Tabs: React.FC<Props> = (props) => {
  const { tabs, initialTabId, tabHeaderControl, tabsLineControl, isTabLineHidden } = props;
  const [currentTabId, setCurrentTabId] = useState(
    initialTabId || (tabs.length ? tabs[0].id : undefined)
  );

  const handleToggleTab = (tab: TabType['id']) => () => {
    setCurrentTabId(tab);
  };

  const params = useParams();

  useEffect(() => {
    setCurrentTabId(initialTabId || (tabs.length ? tabs[0].id : undefined));
  }, [initialTabId, params, tabs]);

  return (
    <div className={b()}>
      {tabs.length > 1 && !isTabLineHidden && (
        <nav className={b('tabsLine')}>
          <div className={b('tabsContainer')}>
            {tabs.map((tab) => (
              <button
                className={b('tab', { current: tab.id === currentTabId })}
                key={tab.id}
                onClick={() => {
                  if (tab.onClick) {
                    tab.onClick();
                  }

                  if (tab.link) {
                    window.open(tab.link, '_blank');
                  }

                  if (tab.component) {
                    handleToggleTab(tab.id)();
                  }
                }}
              >
                {tab.title}
              </button>
            ))}
          </div>

          {tabsLineControl && <div className={b('tabsLineControl')}>{tabsLineControl}</div>}
        </nav>
      )}

      <div className={b('tabContainer')}>
        {tabs.map((tab, index) => {
          const Component = tab.component;

          return (
            <div
              className={b('tabPanel', {
                current: tab.id === currentTabId,
                index: `${index}`,
                onlyOne: tabs.length === 1
              })}
              key={tab.id}
            >
              {tabHeaderControl}
              {Component && <Component {...tab.props} isActive={tab.id === currentTabId} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
