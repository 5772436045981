import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFetchApps, useFetchProduct, useSelectApp } from '@features';
import { useHistory } from 'react-router-dom';
import { AccountSideNav } from '@modules/DashboardModule/components';
import { Header, NavButton } from '@components';
import { useAuth } from '@hooks';
import { RootState } from '../../../../store';
import './AccountModuleLayout.scss';

const b = block('AccountModuleLayout');

export const AccountModuleLayout: React.FC = (props) => {
  const { children } = props;
  const app = useSelector((store: RootState) => store.appManager);
  const user = useSelector((store: RootState) => store.user.user);

  useAuth();
  useFetchApps();
  useFetchProduct();
  useSelectApp(app.current?.id);

  const { t } = useTranslation();
  const history = useHistory();

  const isHasOwnedApp = useMemo(
    () => app.appsList.length > 0 && app.appsList.find((app) => app.ownerId === user.id),
    [app.appsList, user.id]
  );

  return (
    <div className={b({})}>
      <Header
        className={`${b('header')}`}
        leftNav={
          <NavButton
            text={t('dashboard.header.backToProjects')}
            onClick={() =>
              app.current?.id
                ? history.push(`/dashboard/${app.current.id}/stories`)
                : history.push(`/dashboard`)
            }
          />
        }
        withLogo={false}
      />

      <AccountSideNav className={`${b('side-nav')}`} isHasOwnedApp={!!isHasOwnedApp} />

      <section className={b('content')}>{children}</section>
    </div>
  );
};
