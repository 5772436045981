import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { fetchOnboardings, GroupsLine, groupsSlice, GroupsType, LocaleSelector } from '@features';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useGroup } from '@features/groups/hooks';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { IconWarning, Tabs, TabType, Tooltip } from '@components';
import { getStoriesFlattenedArr } from '@utils';
import { GroupStoriesTab, OnboardingSettingsTab } from '../../components';

import './AppOnboardingRoute.scss';

const b = block('AppOnboardingRoute');

export const AppOnboardingRoute: React.FC = () => {
  const { appId, groupId } = useParams<{ appId: string; groupId: string }>();

  const groupsList = useSelector((store: RootState) => store.onboardings);
  const storiesGroup = useGroup(groupId, GroupsType.ONBOARDING);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const stories = useSelector((store: RootState) => store.stories);
  const appLocalizations = useSelector(
    (store: RootState) => store.appManager.current?.appLocalization.languages
  );
  const appLocalizationsArr = appLocalizations?.map((item: any) => item.shortName);
  const [unpublishedStoriesCount, setUnpublishedStoriesCount] = useState(0);
  const [unpublishedLocales, setUnpublishedLocales] = useState<
    {
      id: string;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    const storiesArr = getStoriesFlattenedArr(stories.stories);
    let count = 0;
    const locales: string[] = [];

    storiesArr.forEach((story) => {
      for (const locale in story.storyData) {
        if (story.storyData[locale].history?.story_data && appLocalizationsArr?.includes(locale)) {
          count += 1;
          locales.push(locale);
        }
      }
    });

    setUnpublishedLocales(
      locales
        .filter((item, index) => locales.indexOf(item) === index)
        .map((item) => ({ id: item, title: t(`locales.${item}`) }))
    );
    setUnpublishedStoriesCount(count);
  }, [stories]);

  const { t } = useTranslation();

  const tabsItems: TabType[] = [
    {
      id: 'stories',
      title: t('dashboard.stories.title'),
      component: GroupStoriesTab,
      props: {
        type: GroupsType.ONBOARDING
      }
    },
    {
      id: 'settings',
      title: t('dashboard.onboardings.title'),
      component: OnboardingSettingsTab
    }
  ];

  const [tabs, setTabs] = useState(tabsItems);

  useEffect(() => {
    if (!storiesGroup) {
      setTabs(tabsItems.filter((item) => item.id !== 'settings'));
    } else {
      setTabs(tabsItems);
    }
  }, [storiesGroup]);

  const dashboardRiderectLink = useMemo(
    () =>
      `/dashboard/${appId}/onboarding/${groupsList.onboardings.length ? groupsList.onboardings[0].id : ''
      }`,
    [appId, groupsList.onboardings]
  );

  const handleGroupsLoad = (id: string) => {
    dispatch(fetchOnboardings({ appId: id }));
  };

  const handleGroupSelect = (id: string) => {
    history.push(`/dashboard/${appId}/onboarding/${id}`);

    dispatch(groupsSlice.actions.select(id));
  };

  return (
    <div className={b()}>
      <div className={b('groups')}>
        <GroupsLine
          currentAppId={groupsList.appId ?? undefined}
          groups={groupsList.onboardings}
          loadStatus={groupsList.status}
          redirectLink={dashboardRiderectLink}
          type={GroupsType.ONBOARDING}
          onLoad={handleGroupsLoad}
          onSelect={handleGroupSelect}
        />
      </div>
      <div className={b('tabs')}>
        <Tabs
          tabs={tabs}
          tabsLineControl={
            <div className={b('tabsControl')}>
              {unpublishedStoriesCount > 0 && (
                <Tooltip
                  id="unpublished-stories-tooltip"
                  text={`${t(
                    'dashboard.stories.youHaveUnpublishedChangesText'
                  )} ${unpublishedLocales.map((locale) => locale.title).join(', ')}`}
                >
                  <div className={b('unpublishedStories')} id="unpublished-stories-tooltip">
                    <IconWarning className={b('unpublishedStoriesIcon')} /> (
                    {unpublishedStoriesCount} {t('dashboard.stories.stories')})
                  </div>
                </Tooltip>
              )}

              <LocaleSelector
                isRight
                localesWithWarning={unpublishedLocales.map((locale) => locale.id)}
                theme="light"
              />
            </div>
          }
        />
      </div>
    </div>
  );
};
