import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Auth, createAxiosResponseInterceptor } from '@utils';
import { ErrorFallback } from '@components';
import { App } from './App';
import 'react-error-overlay';
import 'emoji-mart/css/emoji-mart.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './i18n/i18n';
import '@storysdk/core/dist/bundle.css';
import './index.scss';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
createAxiosResponseInterceptor();

Sentry.init({
  dsn:
    'https://f1f6243c4469af3336043158e8914f52@o4508824947589120.ingest.us.sentry.io/4508824959057920',
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, // 100% всех транзакций (можно снизить в проде)
  replaysSessionSampleRate: 0.1, // 10% записей пользовательских сессий
  replaysOnErrorSampleRate: 1.0, // 100% записей при ошибке
  environment: process.env.NODE_ENV, // Устанавливаем среду (dev, prod)
  release: 'storysdk@1.0.0', // Версия проекта
  attachStacktrace: true, // Добавляет стектрейс к ошибкам
  normalizeDepth: 5, // Глубокая нормализация объектов в логах
  sendDefaultPii: true // Отправка данных о пользователях
});

if (Auth.isLoggedIn && Auth.accessToken) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${Auth.accessToken}`
  };
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorFallback error={error as Error} resetErrorBoundary={resetError} />
      )}
    >
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
