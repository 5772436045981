import React, { useState, useMemo, memo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import { RootState, useAppDispatch } from '@store';
import {
  GroupSelector,
  LocaleSelector,
  groupsSlice,
  GroupsType,
  Templates,
  templatesSlice,
  StoryStatus,
  storiesSlice,
  STORY_WIDTH,
  STORY_HEIGHT
} from '@features';
import { CustomGroupControl } from '@storysdk/react';
import { useTranslation } from 'react-i18next';
import { useCurrentStoriesType, useGetCurrentGroup } from '@features/stories/hooks';
import { getStoriesFlattenedArr } from '@utils';
import { HeaderButton, IconShare } from '@components';
import { useCurrentSizePreset } from '@hooks';
import './EditorHeader.scss';
import '@storysdk/react/dist/bundle.css';

const b = block('EditorHeader');

export const EditorHeader: React.FC = memo(() => {
  const history = useHistory();
  const { appId, groupId, templateId, type, category } = useParams<{
    appId?: string;
    groupId?: string;
    templateId?: string;
    type: GroupsType;
    category?: Templates.Category;
  }>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const currentStoriesType = useCurrentStoriesType(type);

  const stories = useSelector((store: RootState) => store[currentStoriesType].stories);
  const appLocale = useSelector((store: RootState) => store.appManager.currentLocale);
  const selectedLayersGroupId = useSelector(
    (store: RootState) => store[currentStoriesType].editor.selectedLayersGroupId
  );
  const currentApp = useSelector((store: RootState) => store.appManager.current);
  const appLocalizations = useSelector(
    (store: RootState) => store.appManager.current?.appLocalization.languages
  );
  const appLocalizationsArr = appLocalizations?.map((item: any) => item.shortName);

  const selectedStory = selectedLayersGroupId ? stories[selectedLayersGroupId] : null;

  const selectedStoryId = selectedLayersGroupId
    ? stories[selectedLayersGroupId].activeLayerId
    : null;

  const isPublished =
    appLocale &&
    selectedStory?.layers[selectedStory.activeLayerId].storyData[appLocale].status ===
    StoryStatus.ACTIVE;

  const groups = useSelector((store: RootState) => store.groups.groups);
  const onboardings = useSelector((store: RootState) => store.onboardings.onboardings);
  const templates = useSelector((store: RootState) => store.templates.templates);
  const currentGroup = useGetCurrentGroup(type, templateId ?? groupId, !!templateId);
  const currentSizePreset = useCurrentSizePreset(currentGroup);

  const [unpublishedLocales, setUnpublishedLocales] = useState<
    {
      id: string;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    const storiesArr = getStoriesFlattenedArr(stories);
    const locales: string[] = [];

    storiesArr.forEach((story) => {
      for (const locale in story.storyData) {
        if (story.storyData[locale].history?.story_data && appLocalizationsArr?.includes(locale)) {
          locales.push(locale);
        }
      }
    });

    setUnpublishedLocales(
      locales
        .filter((item, index) => locales.indexOf(item) === index)
        .map((item) => ({ id: item, title: t(`locales.${item}`) }))
    );
  }, [stories]);

  const getGroups = () => {
    switch (type) {
      case GroupsType.GROUP:
        return groups;
      case GroupsType.ONBOARDING:
        return onboardings;
      case GroupsType.TEMPLATE:
        return templates;
      default:
        return [];
    }
  };

  const groupList = getGroups() as any;
  const currentId = type === GroupsType.TEMPLATE ? templateId : groupId;
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);

  const dashboardGroupsUrl = useMemo(
    () => `/dashboard/${appId}/${type === GroupsType.GROUP ? 'stories' : 'onboarding'}/${groupId}`,
    [appId, groupId, type]
  );
  const dashboardTemplatesUrl = useMemo(() => `/dashboard/templates/${category}`, [category]);

  const handleGoBack = useCallback(() => {
    history.push(type === GroupsType.TEMPLATE ? dashboardTemplatesUrl : dashboardGroupsUrl);
  }, [dashboardGroupsUrl, dashboardTemplatesUrl, history, type]);

  const handleSelectGroup = useCallback(
    (newGroupId: string) => {
      if (type === GroupsType.TEMPLATE) {
        history.push(`/templateEditor/template/${category}/${newGroupId}`);
        dispatch(templatesSlice.actions.select(newGroupId));
      } else {
        history.push(`/editor/${appId}/${type}/${newGroupId}`);
        dispatch(groupsSlice.actions.select(newGroupId));
      }
    },
    [appId, category, dispatch, history, type]
  );

  const handleClosePreviewModal = () => {
    setIsPreviewOpen(false);
  };

  const openShareModal = () => {
    dispatch(storiesSlice.actions.setShareStoryId(selectedStoryId));
  };

  const getCurrentGroupType = () => {
    if (currentGroup.type === GroupsType.TEMPLATE) {
      if (currentGroup.category === Templates.Category.STORIES) {
        return GroupsType.GROUP;
      }
      if (currentGroup.category === Templates.Category.ONBOARDING) {
        return GroupsType.ONBOARDING;
      }
    }

    return currentGroup.type;
  };

  return (
    <div className={b()}>
      <div className={b('column', { left: true })}>
        <HeaderButton
          color="transparent"
          icon="back-fresh"
          iconVariant="editor"
          onClick={handleGoBack}
        >
          {t('editor.header.back')}
        </HeaderButton>
        <LocaleSelector localesWithWarning={unpublishedLocales.map((locale) => locale.id)} />
      </div>
      <div className={b('column')}>
        <GroupSelector currentId={currentId} groups={groupList} onChange={handleSelectGroup} />
      </div>
      <div className={b('column', { right: true })}>
        {isPublished && type !== GroupsType.TEMPLATE && (
          <button className={b('shareBtn')} onClick={openShareModal}>
            <IconShare className={b('shareBtnIcon').toString()} />
          </button>
        )}

        {Object.values(stories).length > 0 && currentGroup && (
          <CustomGroupControl
            group={{
              id: currentGroup.id,
              imageUrl: currentGroup.image,
              title: currentGroup.title,
              type: getCurrentGroupType(),
              settings: currentGroup.settings,
              // @ts-ignore
              stories: getStoriesFlattenedArr(stories)
                .filter((item) => currentLocale && !item.storyData[currentLocale].is_hidden)
                .sort((storyA, storyB) => (storyA.position < storyB.position ? -1 : 1))
                .map((item) => ({
                  ...item,
                  storyData: item.widgets.map((widgetItem) => {
                    let positionByResolutions =
                      widgetItem.positionByResolutions[`${STORY_WIDTH}x${STORY_HEIGHT}`];

                    if (
                      widgetItem.positionByResolutions[
                      `${currentSizePreset.width}x${currentSizePreset.height}`
                      ]
                    ) {
                      positionByResolutions =
                        widgetItem.positionByResolutions[
                        `${currentSizePreset.width}x${currentSizePreset.height}`
                        ];
                    }

                    return {
                      ...widgetItem,
                      position: positionByResolutions
                    };
                  }),
                  positionIndex: item.position
                }))
            }}
            handleCloseModal={handleClosePreviewModal}
            handleNextGroup={() => undefined}
            handlePrevGroup={() => undefined}
            isCacheDisabled
            isEditorMode
            isFirstGroup
            isForceCloseAvailable
            isLastGroup
            isProgressHidden={
              type === GroupsType.TEMPLATE && category === Templates.Category.ONBOARDING
            }
            isShowMockup={currentApp?.settings?.isShowMockup}
            isShowing={isPreviewOpen}
            isStatusBarActive
            startStoryId={selectedStoryId ?? undefined}
            storyHeight={currentSizePreset.height}
            storyWidth={currentSizePreset.width}
          >
            <HeaderButton color="primary" icon="play" onClick={() => setIsPreviewOpen(true)}>
              {t('editor.header.preview')}
            </HeaderButton>
          </CustomGroupControl>
        )}
      </div>
    </div>
  );
});
